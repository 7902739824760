import _ from 'lodash';
import Cookies from 'js-cookie';
import {
  USERS_LOADED,
  USER_REMOVED,
  USERS_PURGE,
  USERS_LOADING,
  USERS_RESIDENT_COUNT_SET,
  INVITE_CODES_LOADED,
  INVITE_CODE_UPDATED,
  INVITE_CODE_REMOVED,
} from './types';
import { userActions } from '../webapi';

export const usersUpdate = (site) => {
  return (dispatch) => {
    userActions.fetchUsers(site).then((res) => {
      if (res.userFetchFail) {
      } else {
        const currentSite = Cookies.get('site');
        if (res.data != null && !_.isEmpty(res.data.results.Items) && res.data.results.Items[0].site === currentSite) {
          const users = res.data.results.Items;
          const allList = [];
          Object.keys(users).forEach((userId) => {
            allList.push({ ...users[userId], id: userId });
          });
          dispatch({
            type: USERS_LOADED,
            payload: allList,
          });
        } else {
          dispatch({
            type: USERS_LOADED,
            payload: [],
          });
        }
      }
    });
  };
};

export const getUserCount = (site) => {
  return (dispatch) => {
    dispatch({
      type: USERS_LOADING,
    });
    userActions.getSiteResidentsCount(site).then((res) => {
      dispatch({
        type: USERS_RESIDENT_COUNT_SET,
        payload: res.data.results,
      });
    });
  };
};

export const usersLoaded = (events) => {
  return {
    type: USERS_LOADED,
    payload: events,
  };
};

export const userKey = (id) => {
  return {
    type: USER_REMOVED,
    payload: id,
  };
};

export const inviteCodesLoaded = (inviteCodes) => {
  return {
    type: INVITE_CODES_LOADED,
    payload: inviteCodes,
  };
};

export const inviteCodeUpdated = (inviteCode) => {
  return {
    type: INVITE_CODE_UPDATED,
    payload: inviteCode,
  };
};

export const inviteCodeRemoved = (inviteCode) => {
  return {
    type: INVITE_CODE_REMOVED,
    payload: inviteCode,
  };
};

export const clearUsers = () => {
  return {
    type: USERS_PURGE,
  };
};
