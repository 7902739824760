import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Text, SideNavItem } from '../../components';

class MasterMenu extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="pageContainer container600 paddingVertical-20 paddingHorizontal-40">
          <Text type="h1" className="marginBottom-40">
            Master Menu
          </Text>
          <SideNavItem Text={'App Assets'} Icon={'mobile'} Link={'/master/appassets'} isFontAwesome noSideDot style={{ paddingLeft: 0 }} />
          <SideNavItem
            Text={'App Store Screenshots'}
            Icon={'file-image-o'}
            Link={'/master/appstore'}
            isFontAwesome
            noSideDot
            style={{ paddingLeft: 0 }}
          />
          <SideNavItem
            Text={'Login Customiser'}
            Icon={'sign-in'}
            Link={'/master/loginpicker'}
            isFontAwesome
            noSideDot
            style={{ paddingLeft: 0 }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(MasterMenu);
