import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Button,
  GenericInput,
  RadioButton,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  DatePicker,
  TimePicker,
  P60Icon,
  AudienceSelector,
  SuccessPopup,
} from '../../components';
import { feedbackActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { getUTCFromTimeDatePickers, safeReadParams } from '../../helper';
import { updatePoll, addRecentlyCreated } from '../../actions';
import FontAwesome from 'react-fontawesome';
import { Text } from '../../components/text';

class AddPoll extends Component {
  initialState = {
    pollId: safeReadParams(this.props, 'pollId'),
    titleInput: '',
    showWarnings: false,
    success: false,
    submitting: false,

    showResults: true,
    anonymous: false,
    shouldNotify: false,

    startDate: moment().format('YYYY-MM-DD'),
    startDateText: moment().format('DD/MM/YYYY'),
    startTime: '12:00pm',

    endDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    endDateText: moment().add(7, 'd').format('DD/MM/YYYY'),
    endTime: '12:00pm',

    questions: [],
    responseIds: [],

    isAudienceValid: true,
    audienceType: '',
    audienceTypeSelection: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const pollId = safeReadParams(nextProps, 'pollId');
    if (pollId) {
      this.loadPoll(pollId);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'polls', this.props.auth, true)) {
        window.history.back();
      } else {
        if (!this.isNew()) {
          this.loadPoll(this.state.pollId);
        }
        if (this.state.questions.length === 0) this.addQuestion();
      }
    }, 500);
    this.props.addRecentlyCreated('surveys');
  }

  isNew() {
    return !this.state.pollId;
  }

  isEditable() {
    return !this.state.responseIds || this.state.responseIds.length === 0;
  }

  loadPoll(pollId) {
    if (!this.props.polls || this.props.polls.length < 1) return;

    const poll = _.find(this.props.polls, (p) => {
      return p != null && p.Id === pollId;
    });

    if (poll != null) {
      const newState = {
        pollId,
        titleInput: poll.Title,
        showResults: poll.ShowResults,
        anonymous: poll.Anonymous,
        questions: poll.Questions,
        startDate: moment(poll.StartTime).format('YYYY-MM-DD'),
        startDateText: moment(poll.StartTime).format('DD/MM/YYYY'),
        startTime: moment(poll.StartTime).format('HH:mm'),
        endDate: moment(poll.EndTime).format('YYYY-MM-DD'),
        endDateText: moment(poll.EndTime).format('DD/MM/YYYY'),
        endTime: moment(poll.EndTime).format('HH:mm'),
        responseIds: poll.ResponseIds || [],
        isAudienceValid: true,
        audienceType: poll.AudienceType || '',
        audienceTypeSelection: poll.AudienceTypeSelection || [],
      };

      newState.questions.forEach((question) => {
        switch (question.Type) {
          case 'multichoice':
          case 'checkbox':
            question.TextOptions = question.Options;
            break;
          case 'scale':
            question.ScaleOptions = question.Options;
            break;
          default:
            break;
        }
      });

      this.setState(newState);
    }
  }

  getQuestionClass(q, type) {
    let className = `question_menu_option ${this.isEditable() ? '' : 'disabled'}`.trim();
    if (q.Type === type) {
      className += ' question_menu_option-active';
    }
    return className;
  }

  selectQuestionType(q, type) {
    q.Type = type;
    this.setState({
      question: this.state.questions,
    });
  }

  addQuestion() {
    this.state.questions.push({
      Id: this.state.questions.length,
      Type: 'multichoice',
      Text: '',
      Description: '',
      TextOptions: [
        {
          Id: 0,
          Text: '',
        },
      ],
      ScaleOptions: [
        {
          Value: 1,
          Text: 'Poor',
        },
        {
          Value: 2,
          Text: 'Not Bad',
        },
        {
          Value: 3,
          Text: 'Average',
        },
        {
          Value: 4,
          Text: 'Good',
        },
        {
          Value: 5,
          Text: 'Excellent',
        },
      ],
    });
    this.setState({
      questions: this.state.questions,
    });
  }

  removeQuestion(index) {
    this.state.questions.splice(index, 1);
    this.setState({
      questions: this.state.questions,
    });
  }

  addTextOption(question) {
    question.TextOptions.push({
      Id: question.TextOptions.length,
      Text: '',
    });
    this.setState({
      questions: this.state.questions,
    });
  }

  removeTextOption(question, index) {
    question.TextOptions.splice(index, 1);
    question.TextOptions.forEach((o, i) => {
      o.Id = i;
    });
    this.setState({
      questions: this.state.questions,
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleQuestionChange(question, key, event) {
    question[key] = event.target.value;
    this.setState({
      questions: this.state.questions,
    });
  }

  handleDateTextChange(value, type) {
    const newState = {};
    const m = moment(value, 'DD/MM/YYYY');

    if (type === 'start') {
      newState.startDateText = value;
      if (m.isValid() && m.year() > 1900) {
        newState.startDate = m.format('YYYY-MM-DD');
      }
    } else {
      newState.endDateText = value;
      if (m.isValid() && m.year() > 1900) {
        newState.endDate = m.format('YYYY-MM-DD');
      }
    }
    this.setState(newState);
  }

  handleStartDateChange(date) {
    this.handleDateChange(date, 'start');
  }

  handleEndDateChange(date) {
    this.handleDateChange(date, 'end');
  }

  handleDateChange(date, type) {
    const newState = {};
    if (type === 'start') {
      newState.startDate = date;
      newState.startDateText = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      newState.showStartDate = false;
    } else {
      newState.endDate = date;
      newState.endDateText = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      newState.showEndDate = false;
    }

    this.setState(newState);
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (_.isEmpty(this.state.titleInput)) {
      return false;
    }
    return true;
  }

  validateStartTime() {
    if (!_.isUndefined(this.state.startTime)) {
      return this.state.startTime.trim() !== '';
    }
    return false;
  }

  validateEndTime() {
    if (!_.isUndefined(this.state.endTime)) {
      return this.state.endTime.trim() !== '';
    }
    return false;
  }

  validateQuestion(q) {
    if (_.isEmpty(q.Text)) {
      return false;
    }
    switch (q.Type) {
      case 'multichoice':
      case 'checkbox':
        return !_.some(q.TextOptions, (o) => {
          return _.isEmpty(o.Text);
        });
      default:
        break;
    }
    return true;
  }

  validateQuestions() {
    let valid = true;
    this.state.questions.forEach((q) => {
      if (!this.validateQuestion(q)) {
        valid = false;
      }
    });
    return valid;
  }

  validateForm() {
    if (!this.validateLoading()) {
      return false;
    }
    if (!this.state.isAudienceValid) {
      return false;
    }
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateStartTime()) {
      return false;
    }
    if (!this.validateEndTime()) {
      return false;
    }
    if (!this.validateQuestions()) {
      return false;
    }
    return true;
  }

  compileJson() {
    const startTime = getUTCFromTimeDatePickers(this.state.startDate, this.state.startTime);
    const endTime = getUTCFromTimeDatePickers(this.state.endDate, this.state.endTime);
    const result = {
      Title: this.state.titleInput,
      ShowResults: this.state.showResults,
      Anonymous: this.state.anonymous,
      Questions: [],
      AudienceType: this.state.audienceType,
      AudienceTypeSelection: this.state.audienceTypeSelection,
      Site: this.props.auth.site,
      StartTime: startTime.valueOf(),
      EndTime: endTime.valueOf(),
      Notification: _.includes(this.props.auth.hidden, 'surveyNotifications') ? false : this.state.shouldNotify,
    };

    if (!this.isNew()) {
      result.Id = this.state.pollId;
    }

    this.state.questions.forEach((q) => {
      const question = {
        Text: q.Text,
        Description: q.Description,
        Id: q.Id,
        Type: q.Type,
        Options: [],
      };
      switch (question.Type) {
        case 'multichoice':
        case 'checkbox':
          q.TextOptions.forEach((o) => {
            question.Options.push({
              Id: o.Id,
              Text: o.Text,
            });
          });
          break;
        case 'scale':
          q.ScaleOptions.forEach((o) => {
            question.Options.push({
              Value: o.Value,
              Text: o.Text,
            });
          });
          break;
        default:
          break;
      }
      result.Questions.push(question);
    });

    return result;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });
    const data = this.compileJson();

    feedbackActions
      .addOrEditPoll(data)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        this.props.updatePoll(res.data.poll);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  onChangeAudience = (audienceType, audienceTypeSelection, isAudienceValid) => {
    this.setState({ audienceType, audienceTypeSelection, isAudienceValid });
  };

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Survey has been ${this.isNew() ? 'added' : 'edited'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
          Save
        </Button>
      </div>
    );
  }

  renderMultiChoiceOption(question, option, index, isSquare) {
    return (
      <div className="multichoiceoption" key={index}>
        <RadioButton single disabled isActive={false} isSquare={isSquare} />
        <div className="multichoiceoption_inputContainer">
          <GenericInput
            id={'qOptionInput_' + question.Id + '_' + index}
            type="text"
            placeholder={`Enter option ${index + 1}`}
            showError={() => {
              return this.state.showWarnings && _.isEmpty(option.Text);
            }}
            isValid={() => {
              return !_.isEmpty(option.Text);
            }}
            value={option.Text}
            onChange={(e) => this.handleQuestionChange(option, 'Text', e)}
            disabled={!this.isEditable()}
          />
        </div>
        {this.isEditable() && question.TextOptions.length > 1 && (
          <P60Icon className="multichoiceoption_remove" icon="remove-circle" onClick={this.removeTextOption.bind(this, question, index)} />
        )}
      </div>
    );
  }

  renderScaleOption(question, option, index) {
    return (
      <div className="scaleoption" key={index}>
        <div
          className="scaleoption_circle"
          style={{
            opacity:
              option.Value /
              _.maxBy(question.ScaleOptions, (o) => {
                return o.Value;
              }).Value,
          }}
        ></div>
        <p className="scaleoption_number">{option.Value}</p>
        <input
          id={'qScaleInput_' + question.Id + '_' + index}
          type="text"
          className="scaleoption_input"
          value={option.Text}
          onChange={(e) => this.handleQuestionChange(option, 'Text', e)}
          disabled={!this.isEditable()}
        />
      </div>
    );
  }

  renderQuestionBottom(question) {
    let content = null;
    switch (question.Type) {
      case 'multichoice':
        content = (
          <div>
            {_.map(question.TextOptions, (o, i) => {
              return this.renderMultiChoiceOption(question, o, i);
            })}
            {this.isEditable() && (
              <div className="clearfix addoption marginTop-16" onClick={this.addTextOption.bind(this, question)}>
                <P60Icon className="addoption_plus" icon="add-circle" />
                <div className="fillSpace">
                  <p className="addoption_text">Add another option</p>
                </div>
              </div>
            )}
          </div>
        );
        break;
      case 'paragraph':
        break;
      case 'scale':
        content = (
          <div className="scalecontainer">
            {_.map(question.ScaleOptions, (o, i) => {
              return this.renderScaleOption(question, o, i);
            })}
          </div>
        );
        break;
      case 'checkbox':
        content = (
          <div>
            {_.map(question.TextOptions, (o, i) => {
              return this.renderMultiChoiceOption(question, o, i, true);
            })}
            {this.isEditable() && (
              <div className="clearfix addoption marginTop-16" onClick={this.addTextOption.bind(this, question)}>
                <P60Icon className="addoption_plus" icon="add-circle" />
                <div className="fillSpace">
                  <p className="addoption_text">Add another option</p>
                </div>
              </div>
            )}
          </div>
        );
        break;
      default:
        break;
    }
    return (
      <div className="question_bottom">
        <GenericInput
          id={'qTextInput_' + question.Id}
          type="text"
          label="Question"
          placeholder="Enter your question here"
          showError={() => {
            return this.state.showWarnings && _.isEmpty(question.Text);
          }}
          isValid={() => {
            return !_.isEmpty(question.Text);
          }}
          value={question.Text}
          onChange={(e) => this.handleQuestionChange(question, 'Text', e)}
          isRequired
          disabled={!this.isEditable()}
          alwaysShowLabel
        />
        <GenericInput
          id={`qDescriptionInput_${question.Id}`}
          type="textarea"
          label="Description"
          placeholder="Enter a description here (optional)"
          value={question.Description}
          onChange={(e) => this.handleQuestionChange(question, 'Description', e)}
          disabled={!this.isEditable()}
          alwaysShowLabel
        />
        {content}
      </div>
    );
  }

  renderQuestion(question, index) {
    const isEditable = this.isEditable();
    return (
      <div className="questionContainer" key={index} style={{ marginTop: index === 0 ? 0 : 32 }}>
        <div className="questionNumber_container">
          <p className="questionNumber">{index + 1}</p>
        </div>
        <div className="question">
          {this.state.questions.length > 1 && isEditable && (
            <div className="cornerCancelButton" onClick={this.removeQuestion.bind(this, index)}>
              <FontAwesome name="minus-circle" className="cornerCancelButton_icon" />
            </div>
          )}
          <div className="question_menu">
            <div
              className={this.getQuestionClass(question, 'multichoice')}
              onClick={() => {
                if (isEditable) this.selectQuestionType(question, 'multichoice');
              }}
            >
              <p className="question_menu_option_text">Multiple Choice</p>
            </div>
            <div
              className={this.getQuestionClass(question, 'paragraph')}
              onClick={() => {
                if (isEditable) this.selectQuestionType(question, 'paragraph');
              }}
            >
              <p className="question_menu_option_text">Paragraph</p>
            </div>
            <div
              className={this.getQuestionClass(question, 'scale')}
              onClick={() => {
                if (isEditable) this.selectQuestionType(question, 'scale');
              }}
            >
              <p className="question_menu_option_text">Scale</p>
            </div>
            <div
              className={this.getQuestionClass(question, 'checkbox')}
              onClick={() => {
                if (isEditable) this.selectQuestionType(question, 'checkbox');
              }}
            >
              <p className="question_menu_option_text">Checkboxes</p>
            </div>
          </div>
          {this.renderQuestionBottom(question)}
        </div>
      </div>
    );
  }

  renderQuestions() {
    return _.map(this.state.questions, (q, i) => {
      return this.renderQuestion(q, i);
    });
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.isNew() ? 'New' : 'Edit'} Survey
          </Text>
          {/* title */}
          <GenericInput
            id="titleInput"
            type="text"
            label="Survey name"
            placeholder="Survey name"
            showError={() => {
              return this.state.showWarnings && _.isEmpty(this.state.titleInput);
            }}
            isValid={() => {
              return !_.isEmpty(this.state.titleInput);
            }}
            value={this.state.titleInput}
            onChange={(e) => this.handleChange(e)}
            isRequired
            disabled={!this.isEditable()}
            alwaysShowLabel
          />
        </div>
        <div className="padding-60 paddingLeft-20">
          <div className="clearfix paddingLeft-40">
            <Text type="formTitleSmall" className="marginBottom-16">
              Survey Questions
            </Text>
          </div>
          {this.renderQuestions()}
          {this.isEditable() && (
            <div className="clearfix addoption marginTop-32 marginLeft-40" onClick={this.addQuestion.bind(this)}>
              <P60Icon className="addoption_plus" icon="add-circle" />
              <div className="fillSpace">
                <p className="addoption_text">Add another question</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderNotification() {
    if (!this.isNew()) return null;
    if (_.includes(this.props.auth.hidden, 'surveyNotifications')) return null;

    return (
      <RadioButton
        className="marginTop-16"
        label="Do you want to send a notification with this survey?"
        isActive={this.state.shouldNotify}
        options={[
          { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
          { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
        ]}
        disabled={!this.isEditable()}
      />
    );
  }

  renderSideForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide">
        <div className="padding-32 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleSmall" className="marginBottom-16">
            Details
          </Text>
          <RadioButton
            label="Will responses be anonymous?"
            isActive={this.state.anonymous}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ anonymous: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ anonymous: false }) },
            ]}
            disabled={!this.isEditable()}
          />
          <RadioButton
            className="marginTop-16"
            label="Will respondents be able to view results?"
            isActive={this.state.showResults}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ showResults: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ showResults: false }) },
            ]}
            disabled={!this.isEditable()}
          />
          {this.renderNotification()}
        </div>
        <div className="padding-32 paddingVertical-40 bottomDivideBorder">
          <AudienceSelector
            onChange={this.onChangeAudience}
            auth={this.props.auth}
            custom
            audienceType={this.state.audienceType}
            audienceTypeSelection={this.state.audienceTypeSelection}
            disallowSingleUsers
            disallowUserType
            maxWidth={300}
            disabled={!this.isEditable()}
          />
        </div>
        <div className="padding-32 paddingVertical-40 bottomDivideBorder ">
          <Text type="formTitleSmall" className="marginBottom-16">
            Starting Date
          </Text>
          <div>
            <GenericInput
              id={`startTime`}
              type="time"
              className="marginTop-16"
              value={this.state.startTime}
              isValid={() => {
                return this.validateStartTime();
              }}
              showError={() => {
                return this.state.showWarnings && !this.validateStartTime();
              }}
              style={{ marginBottom: 0 }}
              inputComponent={
                <TimePicker
                  selectedTime={this.state.startTime}
                  selectTime={(val) => {
                    this.setState({ startTime: val });
                  }}
                  disabled={!this.isEditable()}
                />
              }
              alwaysShowLabel
            />
            {/* Test Input Date Selector */}
            <GenericInput
              className="marginTop-16"
              id="startDateText"
              alwaysShowLabel
              value={this.state.startDateText}
              onChange={(e) => this.handleDateTextChange(e.target.value, 'start')}
              onClick={(e) => this.setState({ showStartDate: !this.state.showStartDate })}
              disabled={!this.isEditable()}
            />
            {this.isEditable() && this.state.showStartDate && (
              <DatePicker selectedDate={this.state.startDate} selectDate={this.handleStartDateChange.bind(this)} />
            )}
          </div>
        </div>
        <div className="padding-32 paddingVertical-40 bottomDivideBorder ">
          <Text type="formTitleSmall" className="marginBottom-16">
            Closing Date
          </Text>
          <div>
            <GenericInput
              id={`endTime`}
              type="time"
              className="marginTop-16"
              value={this.state.endTime}
              isValid={() => {
                return this.validateEndTime();
              }}
              showError={() => {
                return this.state.showWarnings && !this.validateEndTime();
              }}
              style={{ marginBottom: 0 }}
              inputComponent={
                <TimePicker
                  selectedTime={this.state.endTime}
                  selectTime={(val) => {
                    this.setState({ endTime: val });
                  }}
                />
              }
              alwaysShowLabel
            />
            {/* Test Input Date Selector */}
            <GenericInput
              className="marginTop-16"
              id="endDateText"
              alwaysShowLabel
              value={this.state.endDateText}
              onChange={(e) => this.handleDateTextChange(e.target.value, 'end')}
              onClick={(e) => this.setState({ showEndDate: !this.state.showEndDate })}
            />
            {this.state.showEndDate && <DatePicker selectedDate={this.state.endDate} selectDate={this.handleEndDateChange.bind(this)} />}
          </div>
        </div>
      </OverlayPageSection>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="PollContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { polls } = state.feedback;
  const { auth } = state;
  return {
    polls,
    auth,
  };
};

export default connect(mapStateToProps, { updatePoll, addRecentlyCreated })(AddPoll);
