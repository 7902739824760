import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { inviteCodeUpdated } from '../../../actions';
import {
  GenericInput,
  DropdownInput,
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  DatePicker,
  SuccessPopup,
} from '../../../components';
import { userActions, typeActions } from '../../../webapi';
import { checkLoggedIn, validateAccess } from '../../../session';
import { safeReadParams } from '../../../helper';
import { Text } from '../../../components/text';

class AddInviteCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: safeReadParams(this.props, 'code'),
      expiryDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      expiryDateText: moment().add(1, 'days').format('DD/MM/YYYY'),
      submitting: false,
      success: false,
      PageError: null,
      types: [],
      typesLoaded: [],
      selectedType: 'custom',
      selectedUserType: '',
      site: this.props.auth.site,
      showWarnings: false,
      showDate: false,
    };
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'inviteCode', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      if (this.state.code) this.getInviteCode();
      this.getUserTypes();
    }
  }

  getUserTypes = async (site) => {
    const siteToGet = site || (this.props.auth.site === 'hq' ? 'all' : this.props.auth.site);
    if (_.includes(this.state.typesLoaded, siteToGet)) return;

    try {
      const res = await typeActions.getUserTypes(siteToGet);
      res.data.forEach((e) => {
        e.Title = e.displayName;
        e.Key = e.typeName;
      });
      const newTypes = _.uniqBy(_.union(this.state.types, res.data), (t) => `${t.site}_${t.typeName}`);
      const newTypesLoaded = this.state.typesLoaded;
      newTypesLoaded.push(siteToGet);
      this.setState({
        types: _.uniqBy(newTypes, (t) => {
          return `${t.site}_${t.typeName}`;
        }),
        typesLoaded: newTypesLoaded,
      });
    } catch (error) {
      console.log('getUserTypes error', error);
    }
  };

  getInviteCode = async () => {
    try {
      const { data } = await userActions.getInviteCodeByCode(this.state.code);
      const expiry = moment(data.Expiry);
      this.setState({
        expiryDate: expiry.format('YYYY-MM-DD'),
        expiryDateText: expiry.format('DD/MM/YYYY'),
        selectedType: data.Type,
        selectedUserType: data.UserType,
      });
    } catch (error) {
      console.log('getInviteCode error', error);
    }
  };

  getTypeTitle = (userType) => {
    const typeObject = _.find(this.getTypeOptions(), (t) => t.typeName === userType && t.site === this.props.auth.site);
    if (typeObject) return typeObject.displayName;
    return '';
  };

  onSelectUserType = (key) => {
    this.setState({ selectedUserType: key });
  };

  getTypeOptions = () => {
    return _.filter(this.state.types, (t) => t.site === this.props.auth.site);
  };

  handleDateTextChange = (value) => {
    const newU = { expiryDateText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newU.expiryDate = m.format('YYYY-MM-DD');
    }
    this.setState(newU);
  };

  handleDateChange = (date) => {
    var stateChange = {
      expiryDate: date,
      expiryDateText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      showDate: false,
    };
    this.setState(stateChange);
  };

  validateForm = () => {
    return true;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    if (this.state.submitting) return;

    const { auth } = this.props;
    const { code, selectedUserType, expiryDate } = this.state;
    this.setState({ showWarnings: false, submitting: true, PageError: null }, async () => {
      try {
        const expiryStamp = moment(expiryDate).valueOf();
        const { data } = code
          ? await userActions.editInviteCode(code, selectedUserType, expiryStamp)
          : await userActions.createInviteCode(auth.user.Id, auth.site, selectedUserType, expiryStamp);
        // console.log('handleSubmit', data);
        this.setState({ success: true, submitting: false }, () => this.props.inviteCodeUpdated(data));
      } catch (error) {
        this.setState({ submitting: false, PageError: 'Something went wrong. Please try again.' });
        console.log('handleSubmit error', error);
      }
    });
  };

  renderUserType() {
    const { code, selectedType, selectedUserType } = this.state;
    if (code && selectedType !== 'custom') return null;

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, marginBottom: 32 }}>
        <DropdownInput
          id="typeSelect"
          label="User Type"
          placeholder="Type"
          value={this.getTypeTitle(selectedUserType)}
          options={this.getTypeOptions()}
          onSelect={(key) => this.onSelectUserType(key)}
          style={{ marginBottom: 0, width: 250 }}
          disabled={
            !validateAccess('hq', 'inviteCode', this.props.auth) && !validateAccess(this.props.auth.site, 'inviteCode', this.props.auth)
          }
          alwaysShowLabel
        />
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24">
            {`${this.state.code ? 'Edit' : 'New'} Invite Code`}
          </Text>
          <div style={{ width: 'fit-content' }}>
            {this.renderUserType()}
            <GenericInput
              id="expiryDateText"
              label="Expiry Date"
              alwaysShowLabel
              placeholder="20/12/1960"
              value={this.state.expiryDateText}
              onChange={(e) => this.handleDateTextChange(e.target.value)}
              onClick={(e) => this.setState({ showDate: !this.state.showDate })}
            />
            {this.state.showDate && <DatePicker selectedDate={this.state.expiryDate} selectDate={this.handleDateChange} />}
          </div>
        </div>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button inline buttonType="tertiary" onClick={() => window.history.back()} isActive style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()}>
          Save
        </Button>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) return null;

    return (
      <SuccessPopup
        text={`Invite code has been ${this.state.code ? 'updated' : 'created'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to invite codes',
          },
        ]}
      />
    );
  }

  renderError() {
    if (this.state.PageError) {
      return (
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div style={{ fontWeight: 'bold', color: 'red', fontSize: 20 }}>{this.state.PageError}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <OverlayPage>
        {this.renderSuccess()}
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup" style={{ width: '100vw' }}>
            <div style={{ marginBottom: 60 }}>
              {!this.state.success && this.renderForm()}
              {this.renderError()}
            </div>
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { inviteCodeUpdated })(withRouter(AddInviteCode));
